import React from "react"
import { AssetInputPopUpChevron } from "../../components/assets"

export default function HireForm(props) {
  return (
    <div className="hireForm">
      <div className="formContent">
        <form name="hire" method="post" action="/success" data-netlify="true" data-netlify-honeypot="bot-field">
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="hire" />

          <div className="formRow">
            <label className="formInput selectInput">
              <div className="formInputTitle">What best describes you?</div>

              <div className="formInputValueContainer">
                <select className="formInputValue" name="role[]">
                  <option value="none">Select...</option>
                  <option value="pre-seed">Founder of a pre-seed stage startup</option>
                  <option value="seed">Founder of a seed stage startup</option>
                  <option value="early-stage">Founder of a early stage startup</option>
                  <option value="growth-stage">Founder of a growth stage startup</option>
                  <option value="pm">Product Manager working for a startup</option>
                  <option value="designer">Design Lead working for a startup</option>
                  <option value="recruiter">Recruiter</option>
                  <option value="other">Other</option>
                </select>
                <AssetInputPopUpChevron className="popUpIcon" />
              </div>
            </label>

            <label className="formInput selectInput">
              <div className="formInputTitle">Project Type</div>

              <div className="formInputValueContainer">
                <select className="formInputValue" name="type[]">
                  <option value="none">Select...</option>
                  <option value="new-mobile-app">Designing a mobile app from scratch</option>
                  <option value="new-desktop-app">Designing a desktop app from scratch</option>
                  <option value="marketing-site">Designing and building a marketing site</option>
                  <option value="new-front-end">Implementing existing web designs</option>
                  <option value="existing-mobile-app">Improving an existing mobile app</option>
                  <option value="existing-desktop-app">Improving an existing desktop app</option>
                  <option value="existing-web">Improving an existing website</option>
                  <option value="full-time-senior">Full-time senior opportunity</option>
                  <option value="full-time-lead">Full-time lead opportunity</option>
                  <option value="co-founder">Co-founder opportunity</option>
                  <option value="other">Other</option>
                </select>
                <AssetInputPopUpChevron className="popUpIcon" />
              </div>
            </label>
          </div>

          <div className="formRow">
            <label className="formInput selectInput">
              <div className="formInputTitle">Budget</div>

              <div className="formInputValueContainer">
                <select className="formInputValue" name="budget[]">
                  <option value="none">Select...</option>
                  <option value="5-10">$5,000 - $10,000</option>
                  <option value="10-30">$10,000 - $30,000</option>
                  <option value="30-50">$30,000 - $50,000</option>
                  <option value="50-100">$50,000 - $100,000</option>
                  <option value="100-200">$100,000 - $200,000</option>
                  <option value="200-plus">$200,000+</option>
                </select>
                <AssetInputPopUpChevron className="popUpIcon" />
              </div>
            </label>

            <label className="formInput selectInput">
              <div className="formInputTitle">Start Date</div>

              <div className="formInputValueContainer">
                <select className="formInputValue" name="start-date[]">
                  <option value="none">Select...</option>
                  <option value="2-weeks">2 weeks from now</option>
                  <option value="1-month">1 month from now</option>
                  <option value="2-months">2 months from now</option>
                  <option value="3-months">3 months from now</option>
                  <option value="5-months">5 months from now</option>
                  <option value="5-months-plus">6+ months from now</option>
                </select>
                <AssetInputPopUpChevron className="popUpIcon" />
              </div>
            </label>
          </div>

          <div className="formRow">
            <label className="formInput">
              <div className="formInputTitle">Name</div>
              <input className="formInputValue" type="text" name="name" placeholder="Johnny Appleseed" />
            </label>

            <label className="formInput">
              <div className="formInputTitle">Organization</div>
              <input className="formInputValue" type="text" name="organization" placeholder="Appleseed Corp, Inc." />
            </label>
          </div>

          <div className="formRow">
            <label className="formInput">
              <div className="formInputTitle">Email</div>
              <input className="formInputValue" type="email" name="email" placeholder="johnny@appleseed.com" required/>
            </label>

            <label className="formInput">
              <div className="formInputTitle">Phone Number</div>
              <input className="formInputValue" type="tel" name="number" placeholder="(405) 931-0596" />
            </label>
          </div>

          <label className="formInput multilineInput">
            <div className="formInputTitle">Message</div>
            <textarea className="formInputValue" name="message" placeholder="Hi Phil, ..." required></textarea>
          </label>

          <div className="submitRow">
            <button className="submitButton button large primary" type="submit">
              <div className="buttonTitle">Send to Phil</div>
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

import React from "react"

function EngagementMilestone(props) {
  return (
    <div className="engagementMilestone">
      <h4 className="milestoneTitle">{props.title}</h4>
      <p className="milestoneDetail">{props.detail}</p>
    </div>
  )
}

function EngagementDetails(props) {
  const { selection } = props
  switch(selection) {
    case 'item1':
      return (
        <div className="engagementDetails">
          <EngagementMilestone
            title="Brief"
            detail={<span>
              You write a brief outlining deliverables and scope of your project, including timing. The brief should ideally anser the following questions:
            <br/>What are we building? What design problems are we trying to solve? What is expected at the completion of the project? What are the goals and objectives of the new design? What's the target market or audience? What's your budget and schedule? What are the project deliverables?
            </span>}
          />

          <EngagementMilestone
            title="Contract"
            detail="I will send a simple contract detailing the scope, delivery & payment schedule."
          />

          <EngagementMilestone
            title="Work"
            detail="I begin with analysis, follow up with design or development and share the progress with you with each milestone — communicating on a set schedule. Your feedback is important and I will make refinements accordingly."
          />

          <EngagementMilestone
            title="Payment"
            detail="A 20% deposit is due before I start working on your project. Any following invoices will be due in 30 days and payable via a national wire transfer to my US business bank account."
          />

          <EngagementMilestone
            title="Delivery"
            detail="Depending on your needs, this could be source code repo hosted on GitHub, Figma file, and/or a high-fidelity Origami prototype. It is preferred to switch to an hourly engagement at this stage so I can coordinate with your engineers on as-needed basis."
          />
        </div>
      )
    case 'item2':
      return (
        <div className="engagementDetails">
          <EngagementMilestone
            title="Communication"
            detail="Hourly engagement is much more flexible — we can hop on a quick call and we're ready to rock 'n roll from the get-go! I am generally available to Zoom, FaceTime, Google Meet, Slack, or iMessage daily between 11 AM and 6 PM EDT // 8 AM and 3 PM PDT."
          />

          <EngagementMilestone
            title="Work"
            detail="I can work with you up to 20h/week on a as-needed basis – without the need for a fixed scope. There are no surprises and every minute is logged."
          />

          <EngagementMilestone
            title="Billing"
            detail="My rate is $130/hour. Before we begin our engagement, a deposit of $1,000.00 is due which will be deducted from the next invoice. I will send an invoice every 2 weeks. Each invoice is due in 14 days - payable via a national wire transfer to my US business bank account."
          />
        </div>
        )
    default:
      return (
        <div>
          You shouldn't be seeing this error.
        </div>
      )
  }
}

export default function HireProcess(props) {
  const [tabSelection, setTab] = React.useState('item1')

  const handleItem1Change = () => {
    setTab('item1')
  }

  const handleItem2Change = () => {
    setTab('item2')
  }

  return (
    <div className="hireProcess">
      <div className="processContent">
        <div className="processHeader">
          <h3 className="headerTitle">How does it work?</h3>

          <div className="segments">
            <label className="segmentItem" htmlFor="item1">
              <input type="radio" checked={tabSelection === 'item1'} onChange={handleItem1Change} id="item1"/>
              <span className="segmentTitle">Fixed Engagement</span>
            </label>

            <label className="segmentItem" htmlFor="item2">
              <input type="radio" checked={tabSelection === 'item2'} onChange={handleItem2Change} id="item2"/>
              <span className="segmentTitle">Hourly</span>
            </label>
          </div>

          <div className="separator"></div>
        </div>

        <EngagementDetails selection={tabSelection} />
      </div>
    </div>
  )
}

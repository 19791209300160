import React from "react"

export default function ProjectSolution(props) {
  return (
    <section className="projectSolution">
      <div className={`solutionContent ${ props.solutionLayout }`}>
        <div className="solutionDetails">
          <h3 className="solutionTitle">{props.solutionTitle}</h3>
          <p className="solutionDetail">{props.solutionDetail}</p>
        </div>

        <div className="solutionImage">
          {props.solutionImage}
        </div>
      </div>
    </section>
  )
}

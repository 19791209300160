import React from "react"
import HireForm from "./hireForm"
import HireProcess from "./hireProcess"

function BannerTitle(props) {
  const { value } = props
  switch(value) {
    case '':
      return (
        <div className="bannerTitle">Ready to hire Phil?</div>
      )
    default:
      return (
        <h1 className="bannerTitle">{props.value}</h1>
      )
  }
}

function BannerSubtitle(props) {
  const { value } = props
  switch(value) {
    case '':
      return (
        <p className="bannerSubtitle">Let’s ship an amazing experience that your customers will love!</p>
      )
    default:
      return (
        <p className="bannerSubtitle">{props.value}</p>
      )
  }
}

export default function HireBanner(props) {
  return (
    <section className="hireBanner">
      <div className="bannerContainer">
        <div className="bannerHeader">
          <BannerTitle value={props.bannerTitle}/>
          <BannerSubtitle value={props.bannerSubtitle}/>
        </div>

        <div className="separator"></div>

        <div className="bannerContent">
          <HireProcess />

          <div className="separator mobileOnly"></div>

          <HireForm />
        </div>
      </div>
    </section>
  )
}
